import Experience from "../Experience";
import * as THREE from 'three'
import Environment from "./Environment";
import Floor from "./Floor";
import Fox from "./Fox";
import Threes from "./Threes";
import Decoration from "./Decoration";
import Particles from "./Particles";


export default class World {
    constructor(){

        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources

        // const testMesh = new THREE.Mesh( 
        //     new THREE.BoxGeometry(1,1,1),
        //     new THREE.MeshStandardMaterial()
        // )

        // this.scene.add(testMesh)

            this.resources.on('ready' , () => {
                console.log('resources ready')

                this.floor = new Floor()
                this.fox = new Fox()
                this.threes = new Threes()
                this.decoration = new Decoration()
                this.particles = new Particles()
                this.environment = new Environment()
                

            })


     }

     update(){
         if(this.fox){
            this.fox.update()
         }

         if(this.floor){
             this.floor.update()
         }
         if(this.decoration){
             this.decoration.update()
         }
         if(this.particles){
            this.particles.update()
         }
         
     }

   

 }

 