import EventEmitter from "./EventEmiiter";

export default class Time extends EventEmitter {

    constructor(){
        super()
       // console.log('Time Instantiatd')

        this.start = Date.now()
        this.current = this.start
        this.elapsedTime = 0
        this.delta = 16 //Something

        window.requestAnimationFrame(() => {
            this.tick()
        } )

    }

    tick(){

        const currentTime = Date.now()
        this.delta = currentTime - this.current
        this.current = currentTime
        this.elapsedTime = this.current - this.start

        this.trigger('tick')

        //console.log('tick')
        window.requestAnimationFrame(() => {
            this.tick()
        } )

    }

}