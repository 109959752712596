
import Experience from "../Experience";
import * as THREE from 'three'

export default class Fox {

    constructor(){
        this.experience = new Experience()
        this.scene =   this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug
        //Setup
        this.resource = this.resources.items.foxModel
        this.helper = this.experience.helper
        this.lastValue = 0 
        if(this.debug.active){
            this.debugFolder = this.debug.ui.addFolder('fox')
        }

        this.setModel()
        this.setAnimation()
        
    }

    setModel(){
        this.model = this.resource.scene
        this.model.scale.set(0.02, 0.02, 0.02)
        this.scene.add(this.model)

        this.model.traverse((child) => {
            if(child instanceof THREE.Mesh){
                child.castShadow = true
            }
        })
    }

    setAnimation(){
        this.animation = {}

        this.animation.mixer = new THREE.AnimationMixer(this.model)
        this.animation.action = this.animation.mixer.clipAction(this.resource.animations[0])
        //this.animation.action.play()

        this.animation.actions = {}
        this.animation.actions.idle = this.animation.mixer.clipAction(this.resource.animations[0])
        this.animation.actions.walking = this.animation.mixer.clipAction(this.resource.animations[1])
        this.animation.actions.running = this.animation.mixer.clipAction(this.resource.animations[2])

        this.animation.actions.current = this.animation.actions.idle
        this.animation.actions.current.play()

        this.animation.play = (name) => {
            const newAction = this.animation.actions[name]
            const oldAction = this.animation.actions.current
            newAction.reset()
            newAction.play()
            newAction.crossFadeFrom(oldAction, 1)

            this.animation.actions.current = newAction
        }

        //Debug

        if(this.debug.active) {

            const debugObject = {
                playIdle: () => {this.animation.play('idle')},
                playWalking: () => {this.animation.play('walking')},
                playRunning: () => {this.animation.play('running')}
            }

            this.debugFolder.add(debugObject, 'playIdle')
            this.debugFolder.add(debugObject, 'playWalking')
            this.debugFolder.add(debugObject, 'playRunning')

        }
    }

    update(){
        let index = this.helper.values.currentSection
        let lastValue = this.lastValue

        if (index != lastValue){
            this.lastValue = index
            console.log(index)
            console.log(lastValue)

            switch(index) {
                case 0:
                      this.animation.play('idle')
                    break;
                case 1:
                     this.animation.play('walking')
                   break;
                case 2:
                     this.animation.play('running')
                    break;
                default:
                 break;
                }
    

        }
    
   
        this.animation.mixer.update(this.time.delta * 0.001)

    }
}