import Experience from "../Experience";
import * as THREE from 'three'
import Environment from "./Environment";
import Floor from "./Floor";
import Fox from "./Fox";

export default class Threes  {

    constructor(){
        this.experience = new Experience ()
        this.scene =   this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug
        this.resource = this.resources.items.threes

        this.setModel()
    }

    setModel(){
        this.model = this.resource.scene
        this.model.scale.set(5, 5, 5)
        this.scene.add(this.model)

        this.model.traverse((child) => {
            if(child instanceof THREE.Mesh){
                child.castShadow = true
            }
        })
    }


}