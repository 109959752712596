import * as THREE from 'three'
import Sizes from "./Utils/Sizes"
import Time from "./Utils/Time"
import Camera from './Camera'
import Renderer from './Renderer'
import World from './World/World'
import Resources from './Utils/Resources'
import sources from './sources.js'
import Debug from './Utils/Debug'
import Helper from './World/Helper'

let instance = null

export default class Experience {

    constructor(canvas){

        if (instance){
            return instance
        }

        instance = this

        //Global access
        window.experience = this
        //Options
        this.canvas = canvas
        
        this.debug = new Debug()
        this.sizes = new Sizes()
        this.time = new Time()
        this.scene = new THREE.Scene()
        this.resources = new Resources(sources)
        this.camera = new Camera()
        this.renderer = new Renderer()
        this.helper = new Helper()
        this.world = new World()
        //Size resized
        this.sizes.on('resize', () => 
        {
            this.resize()
        })

        //Time tick
        this.time.on('tick', ()  => {
            this.update()
        } )

    }

    resize() {
        console.log('A resize ocurred')
        this.camera.resize()
        this.renderer.resize()
    }

    update(){
        this.camera.update()
        this.world.update()
        this.renderer.update()
    }

    destroy(){
        this.sizes.off('resize')
        this.sizes.off('tick')

        //Traverse scene

        this.scene.traverse((child) => {
            
            if(child instanceof THREE.Mesh){
                child.geometry.dispose()

                for (const key in child.material){
                    const value = child.material[key]

                    if(value && typeof value.dispose === 'function'){
                        value.dispose()
                    }

                }
            }

        })

        this.camera.controls.dispose()
        this.renderer.instance.dispose()
        //dispose post processings
        (this.debug.active)
        {
            this.debug.ui.destroy()
        }

    }

}