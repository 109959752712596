export default [
    {
        name: 'environmentMapTexture',
        type: 'cubeTexture',
        path:
        [
            'textures/environmentMap/px.jpg',
            'textures/environmentMap/nx.jpg',
            'textures/environmentMap/pz.jpg',
            'textures/environmentMap/nz.jpg',
            'textures/environmentMap/pz.jpg',
            'textures/environmentMap/nz.jpg'
        ]
    },
    {

        name: 'grassColorTexture',
        type: 'texture',
        path: 'textures/dirt/color.jpg'
    },
    {

        name: 'grassNormalTexture',
        type: 'texture',
        path: 'textures/dirt/normal.jpg'
    } ,
    {
        name: 'foxModel',
        type: 'gltfModel',
        path: 'models/Fox/glTF/Fox.gltf'

    },
    {
        name: 'matcap1',
        type: 'texture',
        path: 'textures/matcap/mat1.png'

    },
    {
        name: 'threes',
        type: 'gltfModel',
        path: 'models/Threes/threes.glb'

    },
    {
        name: 'decoration',
        type: 'gltfModel',
        path: 'models/Threes/decoration.glb'

    }
    


]